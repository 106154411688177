
var $ = require("jquery");
require("jquery.ripples");
$(document).ready(function () {
  console.log("ready!");
  $("html").ripples({
    resolution: 512,
    dropRadius: 20,
    perturbance: 0.01,
  });
});
